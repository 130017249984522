import React, { useEffect, useState } from 'react';
import './App.css';
import './sprite.css';
import PageSelector from './components/pageSelector';
import AuthContext from './contexts/authContext';
import { useIdleTimer } from 'react-idle-timer'
import LoginEffect from './effects/loginEffect';

function App() {

  const [auth, setAuth] = useState({authDict: {}, isLoggedIn: false});

  useEffect(() => LoginEffect(setAuth), []);

  const handleOnIdle = () => {
    localStorage.removeItem('auth');
    setAuth({authDict: {}, isLoggedIn: false});
  }

  const handleOnActive = event => {
    console.log(getRemainingTime());
  }
  
  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500
  })

  return (
    <div className="App">
      <AuthContext.Provider value={auth}>
        <PageSelector isLoggedIn={auth.isLoggedIn}></PageSelector>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
