
import jwt_decode from "jwt-decode";

function LoginEffect(setAuth) {
    const authResult = window.location.hash.replace('#', '');

    const hashParams = new URLSearchParams(authResult);
    let authDict = Object.fromEntries(hashParams);     
    
    const queryParams = new URLSearchParams(window.location.search);
    const isLogout = queryParams.get('logout');

    if ('access_token' in authDict) {
      localStorage.setItem('auth', JSON.stringify(authDict));
      setAuth({authDict, isLoggedIn: true});
      window.history.replaceState(null, null, ' ');
    }
    else if(isLogout){
      localStorage.removeItem('auth');
      setAuth({authDict: {}, isLoggedIn: false});
      window.history.replaceState(null, null, window.location.pathname);
    }
    else {
      const localAuth = localStorage.getItem('auth');
      if(localAuth !== null) {
        authDict = JSON.parse(localAuth);
        const jwt = jwt_decode(authDict.id_token);
        if(jwt.exp <= Math.floor(Date.now() / 1000)) {
          localStorage.removeItem('auth');
        }
        else {
          //should get refresh token here
          setAuth({authDict, isLoggedIn: true});
        }
      }
    }
}

export default LoginEffect;