/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import WmaData from '../data/wma-data.json';

import { makeStyles } from "@material-ui/core/styles";

export default function SearchBar({onSearch, style, theme}) {

  const useStyles = makeStyles(() => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        transform: "translate(34px, 20px) scale(1);"
      },
      "& .Mui-focused": {
        color: theme.primaryColor
      },
      "& .MuiFormLabel-root": {
        color: theme.primaryColor
      }
    },
    inputRoot: {
      color: theme.primaryColor,
      backgroundColor: theme.secondaryColor,
      opacity: theme.inputOpacity,
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        paddingLeft: 26
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.primaryColor
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.primaryColor
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.primaryColor
      }
    },
  }));

  const classes = useStyles();

  const searchChange = (event, value) => {
    const selected = WmaData.find(wma=> wma.Name === value);
    onSearch(selected);
  };

  return (
    <div style={{...style, width: '400px'}}>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        classes={classes}
        options={WmaData.map((option) => option.Name)}
        onChange={searchChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search by WMA name or lat/lng"
            margin="none"
            variant="outlined"        
            InputProps={{ ...params.InputProps, type: 'search' }}
          />
        )}
      />
    </div>
  );
}
