import React from 'react';
import Button from '@material-ui/core/Button';

function LoginButton({isLoggedIn, style}) {
    const stripTrailingSlash = (str) => {
        return str.endsWith('/') ?
            str.slice(0, -1) :
            str;
    };

    const loginClick = () => {
        let redirectUrl = stripTrailingSlash(window.location.href);
        if(!isLoggedIn)
        {
            window.location = 'https://auth.georgiapublichuntingland.com/login?client_id=1jcnqrdd7hulsbqe2em9qjvhp9&response_type=token&scope=email+openid+phone&redirect_uri=' + encodeURIComponent(redirectUrl);
        }
        else{
            redirectUrl += '?logout=true';
            window.location = 'https://auth.georgiapublichuntingland.com/logout?client_id=1jcnqrdd7hulsbqe2em9qjvhp9&logout_uri=' + encodeURIComponent(redirectUrl);
        }
    };

    return (
        <Button variant="contained" color="primary" onClick={loginClick} style={style}>
            { isLoggedIn ? <span>SignOut</span> :  <span>Sign in</span> }
        </Button>
    );
}

export default LoginButton;