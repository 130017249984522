import React, { useEffect, useState, useContext} from 'react';
import Map from '../components/mapBoxMap';
import HuntDetails from '../components/huntDetails';
import ApiClient from '../data/api-client';
import AuthContext from '../contexts/authContext';
import Paper from '@material-ui/core/Paper';

function Search({isLoggedIn, searchLocation, newWaypoint, onMapViewChange, onMapStyleChanged}) {
    const [location, setLocation] = useState(searchLocation);
    const [waypoints, setWaypoints] = useState([]);
    const [huntDetails, setHuntDetails] = useState([]);
    const [huntDetailsVisible, showHuntDetails] = useState(false);    
    const [waypointModal, setWaypointModal] = useState(null);
    const authContext = useContext(AuthContext);

    // const searchChange = (selectedItem) => {
    //     setSearchLocation({lat: selectedItem.Location.Lat, lng: selectedItem.Location.Lng});
    //     setHuntDetails(selectedItem.hunts);
    //     sessionStorage.setItem('searchLocation', `${selectedItem.Location.Lat},${selectedItem.Location.Lng}`);
    // };

    // const addWaypoint = () => {
    //     const waypoint = {...{ id: uuidv4(), isNew: true }, ...searchLocation};
    //     setWaypoints([...waypoints, waypoint]);
    //     setWaypointModal(waypoint);
    // };

    // const viewHunts = () => {
    //     showHuntDetails(true);
    // };    
    
    const onViewportChange = (viewport) => {
        setLocation({lat: viewport.latitude, lng: viewport.longitude});
        sessionStorage.setItem('searchLocation', `${viewport.latitude},${viewport.longitude}`);
        onMapViewChange(viewport);
    }; 
    
    const onWaypointDrag = (dragData, id) => {
        const waypointsCopy = [...waypoints];
        const waypoint = waypointsCopy.find(wp => wp.id === id);
        waypoint.lat = dragData.lngLat[1];
        waypoint.lng = dragData.lngLat[0];
        setWaypoints(waypointsCopy);
    };

    const onWaypointCancelled = (waypoint) => {   
        if(waypoint.isNew) {
            let waypointsCopy = [...waypoints];  
            waypointsCopy.pop();   
            setWaypoints(waypointsCopy);
        }
        setWaypointModal(null);
    };

    const onWaypointSave = (waypointInfo) => {   
        const waypointsCopy = [...waypoints];
        let waypointIndex = waypointsCopy.findIndex(wp => wp.id === waypointInfo.id);
        const updatedWaypoint = {...waypointsCopy[waypointIndex], ...waypointInfo};    
        delete updatedWaypoint.isNew;
        
        ApiClient.PostData('https://api.georgiapublichuntingland.com/save-waypoint', updatedWaypoint, authContext.authDict.id_token)
        .then(data => {
            waypointsCopy[waypointIndex] = updatedWaypoint;
            setWaypoints(waypointsCopy);
            setWaypointModal(null);
        });
        
    };
    
    const onWaypointClick = (id) => {
        const waypoint = waypoints.find(wp => wp.id === id);
        setWaypointModal(waypoint);
    };

    useEffect(() => {
        if(sessionStorage.getItem('searchLocation')) {
            const latLng = sessionStorage.getItem('searchLocation').split(',');
            setLocation({lat: parseFloat(latLng[0]), lng: parseFloat(latLng[1])});
        }

    }, []);

    useEffect(() => {
        if(isLoggedIn) {
            ApiClient.GetData('https://api.georgiapublichuntingland.com/get-waypoints', authContext.authDict.id_token)
            .then(data => {
                setWaypoints(data.Items);
            });
        }
        else {
            setWaypoints([]);
        }
    }, [isLoggedIn, authContext.authDict.id_token]);

    useEffect(() => {
        if(searchLocation) {
            setLocation({lat: searchLocation.Location.Lat, lng: searchLocation.Location.Lng});
            setHuntDetails(searchLocation.hunts);
            sessionStorage.setItem('searchLocation', `${searchLocation.Location.Lat},${searchLocation.Location.Lng}`);
        }
    }, [searchLocation]);
        
    useEffect(() => {
        if(newWaypoint) {
            setWaypoints([...waypoints, newWaypoint]);
            setWaypointModal(newWaypoint);
        }
    }, [newWaypoint, waypoints]);

    if(!location) {
        return (
            <Paper style={{ backgroundColor: 'transparent', textAlign: 'left' }} elevation={0}>
                <img alt="" style={{backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(searchSplash.jpg)`, width: '100%', height: '100vh'}} />
            </Paper>
        )
    }

    return (
        <div>
            <Map 
                location={location} 
                onViewportChange={onViewportChange} 
                waypoints={waypoints} 
                onWaypointDrag={onWaypointDrag}
                waypointModal={waypointModal}
                onWaypointSave={onWaypointSave}
                onWaypointModalCanceled={onWaypointCancelled}
                onWaypointClick={onWaypointClick}
                onMapStyleChanged={onMapStyleChanged}
            >                    
            </Map>
            <HuntDetails 
                huntDetails={huntDetails} 
                huntDetailsVisible={huntDetailsVisible} 
                setHuntDetailsVisible={showHuntDetails}
            >                
            </HuntDetails>
        </div>
    );
}

export default Search;