import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Header from './header';
import Search from '../pages/search';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: 0,
    },
    closeMenuButton: {
        marginRight: 'auto',
        marginLeft: 0,
    },
}));

const themes = {
    dark:  {
        primaryColor: '#000',
        secondaryColor: '#FFF',
        inputOpacity: '90%'
    },
    light: {
        primaryColor: '#FFF',
        secondaryColor: '#000',
        inputOpacity: '50%'
    }
};

function PageSelector({isLoggedIn}) {
    const classes = useStyles();
    const [searchLocation, setSearchLocation] = useState(null);
    const [waypoint, setWaypoint] = useState(null);
    const [currentTheme, setTheme] = useState(themes.light);
    const d = new Date();
    const copyrightYear = d.getFullYear();
    
    const addWaypoint = () => {
        const newWaypoint = {...{ id: uuidv4(), isNew: true }, ...{lat: searchLocation.Location.Lat, lng: searchLocation.Location.Lng}};
        setWaypoint(newWaypoint);
    };

    const onMapStyleChanged = (newStyle) => {
        setTheme(newStyle.indexOf('satellite') > -1 ? themes.light : themes.dark);
    };

    const onMapViewChange = (viewport) => {
        setSearchLocation({Location: {Lat: viewport.latitude, Lng: viewport.longitude}});
    }
              
    return (
        <div className={classes.root}>
            <CssBaseline />

            <Header onSearchChange={setSearchLocation} onAddWaypoint={addWaypoint} theme={currentTheme}></Header>
            
            <div className={classes.content}>
                <Search isLoggedIn={isLoggedIn} onMapViewChange={onMapViewChange} searchLocation={searchLocation} newWaypoint={waypoint} onMapStyleChanged={onMapStyleChanged}></Search>
            </div> 

            <div style={{position: 'absolute',bottom: '0px', fontSize: '.7rem', textAlign: 'center', left: '50%'}}>
                <div style={{position: 'relative', backgroundColor: 'white', padding: '5px', opacity: '60%',  left: '-80px'}}>
                    <div><Link href="faq.html">FAQ</Link> | <Link href="privacy.html">Privacy</Link></div>
                    <div>© Copyright {copyrightYear}, Hysonix LLC</div>
                </div>
            </div>
        </div>
    );
}

export default PageSelector;