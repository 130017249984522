import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    },
}));

const Hunts = ({hunts}) => {

    return hunts.map((hunt) => {
        //{registrationType: "SIGNIN", species: {…}, weapon: "Archery", startDate: "9/12/2020", endDate: "10/9/2020"}}
        return(
            <div style={{border: 'solid 1px #000', marginBottom: '10px', borderRadius: '5px', padding: '5px'}}>
                <div>
                    <span style={{fontWeight: 'bold'}}>Dates: </span><span>{hunt.startDate} - {hunt.endDate}</span>
                </div>
                <div>
                    <span style={{fontWeight: 'bold'}}>Weapon: </span><span>{hunt.weapon}</span>
                </div>
                <div>
                    <span style={{fontWeight: 'bold'}}>Registration Type: </span><span>{hunt.registrationType}</span>
                </div>
                <div>
                    <span style={{fontWeight: 'bold'}}>Restrictions: </span><span>{hunt.species.restriction}</span>
                </div>
            </div>
        );
     });

};
 
    
const Seasons = ({huntDetails, classes}) => {    
    const species = [...new Set(huntDetails.map(({species})=>species.name))]
    return species.map((s) => {
        const hunts = huntDetails.filter(h => h.species.name === s);
        const sortedHunts = hunts.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

        return (
            <div>
                <div className={classes.colorPrimary}><h3>{s}</h3></div>
                <Hunts hunts={sortedHunts}></Hunts>
            </div>
        );
    });

};


function HuntDetails({huntDetails, huntDetailsVisible, setHuntDetailsVisible}) {
    const classes = useStyles();  
    const [modalStyle] = useState(getModalStyle);    
    
    const hideHunts = () => {
        setHuntDetailsVisible(false);
    };     

    return (
        <Modal
            open={huntDetailsVisible}            
            onClose={hideHunts}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            >
            <div style={modalStyle} className={classes.paper}>
                <h2 id="modal-title">Available Hunts</h2>
                <p id="modal-description">
                    <Seasons huntDetails={huntDetails} classes={classes}></Seasons>
                </p>
            </div>
        </Modal>
    );
}

export default HuntDetails;