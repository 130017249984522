import { makeStyles } from '@material-ui/core/styles';
import Color from 'color';

const useWaypointStyles = makeStyles((theme) => {
    const baseColors = {
        parking: '#1f74ff',
        waypoint: '#ff1f1f',
        deer: '#705656',
        foodsource: '#089c0d'
    };

    const baseStyle = 
    {
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        }
    };

    Object.keys(baseColors).forEach(function(key) {
        const opaqueColor = Color(baseColors[key]).alpha(0.5).lighten(0.5);
        baseStyle[key] = { backgroundColor: baseColors[key] };
        baseStyle[key + '-marker'] = {
            backgroundColor: baseColors[key],
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            border: '4px solid ' + opaqueColor.hex()
        }
    });

    return baseStyle;
});

export default useWaypointStyles;