import React, { useEffect, useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faParking, faCopy } from '@fortawesome/free-solid-svg-icons';
import FormatLatOrLng from '../utils/latLngUtils';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useWaypointStyles from '../styles/waypointStyles';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const WaypointGroupSelector = ({classes}) => {
    const waypointTypes = [
        { name: 'deer-hunting', label: 'Deer Hunting' },
        { name: 'scouting', label: 'Scouting' },
        { name: 'other', label: 'Other' }
    ];
    return (
    <div style={{paddingTop: '20px'}}>
        <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" style={{textAlign: 'left'}}>Waypoint Groups</FormLabel>
            <FormGroup row>
                {waypointTypes.map(wp => (
                    <FormControlLabel
                        key={wp.name}
                        control={<Checkbox name={wp.name} checked={true} />}
                        label={wp.label}
                    />
                ))};
            </FormGroup>
        </FormControl>
    </div>);
};

const WaypointTypes = ({waypointTypeClick, waypointType, classes}) => {
    return (        
        <div>
        <WaypointType 
            name="Waypoint" 
            waypointType="waypoint"
            className={classes.waypoint}
            selectedType={waypointType} 
            icon={(<FontAwesomeIcon icon={faMapMarker} style={{marginRight: 5}} />)}
            onWaypointTypeClick={waypointTypeClick}
        >
        </WaypointType>
        <WaypointType 
            name="Parking" 
            waypointType="parking"
            className={classes.parking}
            selectedType={waypointType} 
            icon={(<FontAwesomeIcon icon={faParking} style={{marginRight: 5}} />)}
            onWaypointTypeClick={waypointTypeClick}
        >
        </WaypointType>  
        <WaypointType 
            name="Deer" 
            waypointType="deer"
            className={classes.deer}
            selectedType={waypointType} 
            icon={(<span className="icon icon-deer" style={{marginRight: 5, color: '#FFF', zoom: .5}}></span>)}
            onWaypointTypeClick={waypointTypeClick}
        >
        </WaypointType>  
        <WaypointType 
            name="Food Source" 
            waypointType="foodsource"
            className={classes.foodsource}
            selectedType={waypointType} 
            icon={(<span className="icon icon-acorn" style={{marginRight: 5, color: '#FFF', zoom: .5}}></span>)}
            onWaypointTypeClick={waypointTypeClick}
        >
        </WaypointType>                      
    </div>);
};

const WaypointType = ({name, waypointType, className, icon, selectedType, onWaypointTypeClick}) => {    
    const getOpacity = () => waypointType === selectedType ? '100%' : '20%';
    const defaultStyle = { opacity: getOpacity(), color: '#FFF', margin: 5 };

    return (
        <Button 
            variant="contained" 
            style={defaultStyle} 
            disableElevation
            className={className}
            onClick={() => { onWaypointTypeClick(waypointType); }}
        >
            {icon}
            {name}
        </Button>  
    );
};

function WaypointModal({waypoint, onWaypointSave, onCancel}) {
    const classes = useWaypointStyles();  
    const [modalStyle] = useState(getModalStyle); 
    const [waypointName, setWaypointName] = useState('');
    const [waypointType, setWaypointType] = useState('');

    const saveWaypoint = () => {
        onWaypointSave({...waypoint, ...{name: waypointName, type: waypointType}});
    };

    const waypointTypeClick = (type) => {
        setWaypointType(type);
    };

    const copyToClipboard = () => toast("Copied to clipboard");

    useEffect(() => {
        setWaypointName(waypoint && waypoint ? waypoint.name : '');
        setWaypointType(waypoint && waypoint.type ? waypoint.type : 'waypoint');
    }, [waypoint]);

    if(!waypoint) return <></>;

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={true} 
            />
            <div style={modalStyle} className={classes.paper}>
                <form className={classes.root} noValidate autoComplete="off">
                    <div style={{float: 'right'}}>
                        <span style={{fontSize: '.7rem'}}>{FormatLatOrLng(waypoint.lat)}, {FormatLatOrLng(waypoint.lng)}</span>                        
                        <CopyToClipboard onCopy={copyToClipboard} text={FormatLatOrLng(waypoint.lat) + ',' + FormatLatOrLng(waypoint.lng)}>
                            <Button variant="contained" disableElevation style={{marginLeft: '5px', minWidth: '20px', width: '20px', color: '#FFF'}}>
                                <FontAwesomeIcon icon={faCopy} />
                            </Button>
                        </CopyToClipboard>
                    </div>
                    <div>
                        <TextField style={{width: '100%'}} id="standard-basic" label="Name" value={waypointName} onChange={event => setWaypointName(event.target.value)} />
                    </div>
                    <WaypointGroupSelector classes={classes}></WaypointGroupSelector>
                    <WaypointTypes classes={classes} waypointType={waypointType} waypointTypeClick={waypointTypeClick}></WaypointTypes>
                    <div>
                        <Button variant="contained" disableElevation color="primary" onClick={saveWaypoint} style={{marginRight: '5px'}}>Save</Button>
                        <Button variant="contained" disableElevation onClick={() => { onCancel(waypoint); }} color="secondary">Cancel</Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default WaypointModal;