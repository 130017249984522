import React, { useState } from "react";
import ReactMapGL, { Source, Layer, Marker, FullscreenControl, ScaleControl, NavigationControl, GeolocateControl } from 'react-map-gl';
import geojson from '../data/georgia-mapdata.json';
import WmaData from '../data/wma-data.json';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import WaypointModal from './waypointModal';
import useWaypointStyles from '../styles/waypointStyles';

import 'mapbox-gl/dist/mapbox-gl.css';

const Mark = ({ markerType, onClick }) => {
    const classes = useWaypointStyles();
    const markerStyle = classes[markerType + '-marker'];
    return (
        <div onClick={onClick} className={markerStyle}></div>
    )
};

const Waypoints = ({ waypoints, onWaypointDrag, onWaypointClick }) => {
    if (waypoints.length === 0) return <></>;

    return waypoints.map(({ id, lat, lng, type }) => {
        return (
            <Marker
                key={id}
                latitude={lat}
                longitude={lng}
                offsetLeft={-20}
                offsetTop={-10}
                draggable={true}
                onDragEnd={dragData => { onWaypointDrag(dragData, id); }}
            >
                <Mark markerType={type} onClick={() => { onWaypointClick(id); }}></Mark>
            </Marker>
        );
    });
};

const WmaMark = ({Name}) => {
    return <p style={{ color: '#FFF', textShadow: '1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000'}}>{Name}</p>
};

const WmaMarkers = ({zoom}) => {

    console.log(zoom);

    //if(zoom < 9) return <Mark markerType={'parking'} onClick={() => { console.log({Name}.Name); alert({Name}.Name); }}></Mark>;

    return WmaData.map(({ Name, Location }) => {
        return (
            <Marker
                key={Name}
                latitude={Location.Lat}
                longitude={Location.Lng}
            >
                <WmaMark Name={Name}></WmaMark>
            </Marker>
        );
    });
};

export default function Map({ location, waypoints, onViewportChange, onMapStyleChanged, onWaypointDrag, waypointModal, onWaypointSave, onWaypointModalCanceled, onWaypointClick }) {
    const [style, setStyle] = useState('mapbox/satellite-v9');
    const [zoom, setZoom] = useState(13);

    const accessToken = 'pk.eyJ1IjoiZGh5c29uZyIsImEiOiJja2V1bzR4NTcwMHRmMnhvMnE2dmFidGM3In0.X_oHHSnQvmWEJalSL3YdmA';


    const menuStyle = {
        'position': 'absolute',
        'background': '#fff',
        'bottom': '50px',
        'right': '5px'
    };

    const linePaint = {
        id: 'linePaint',
        type: 'line',
        paint: {
            'line-color': '#000'
        }
    };

    const fillPaint = {
        id: 'fillPaint',
        type: 'fill',
        paint: {
            'fill-color': 'white',
            'fill-opacity': .05,
            'fill-outline-color': 'black'
        }
    };

    const viewPortChange = (viewport) => {
        setZoom(viewport.zoom);
        onViewportChange(viewport);
    };

    const onMapTypeChange = (event, newStyle) => {
        setStyle(newStyle);
        onMapStyleChanged(newStyle);
    };

    const StyleMenu = () => {
        return (
            <ToggleButtonGroup size="small" color="primary" value={style} exclusive onChange={onMapTypeChange} style={menuStyle} aria-label="outlined primary button group">
                <ToggleButton value={'mapbox/streets-v11'}>street</ToggleButton>
                <ToggleButton value={'dhysong/ckf1szjsg2oxj19tac6c7rucp'}>terrain</ToggleButton>
                <ToggleButton value={'mapbox/satellite-v9'}>satellite</ToggleButton>
            </ToggleButtonGroup>
        );
    };

    if (location === null) return <></>;

    return (

        <div id={'mapContainer'} style={{ position: 'relative' }}>
            <ReactMapGL
                mapboxApiAccessToken={accessToken}
                latitude={location.lat}
                longitude={location.lng}
                width={'100vw'}
                height={'100vh'}
                zoom={zoom}
                showZoom={true}
                mapStyle={'mapbox://styles/' + style}
                onViewportChange={viewPortChange}
                onMapTypeChange={onMapTypeChange}
            >
                <Source type="geojson" data={geojson}>
                    <Layer {...fillPaint} />
                    <Layer {...linePaint} />
                </Source>
                <WmaMarkers zoom={zoom}></WmaMarkers>
                <Waypoints waypoints={waypoints} onWaypointDrag={onWaypointDrag} onWaypointClick={onWaypointClick}></Waypoints>
                <div style={{ position: 'absolute', right: 5, bottom: 260 }}>
                    <FullscreenControl container={document.querySelector('#mapContainer')} />
                </div>
                <div style={{ position: 'absolute', right: 5, bottom: 225 }}>
                    <GeolocateControl
                        positionOptions={{ enableHighAccuracy: true }}
                        trackUserLocation={true}
                    />
                </div>
                <div style={{ position: 'absolute', right: 5, bottom: 130 }}>
                    <NavigationControl />
                </div>
                <div style={{ position: 'absolute', bottom: 25, right: 5 }}>
                    <ScaleControl maxWidth={200} unit={"metric"} />
                </div>
            </ReactMapGL>
            <StyleMenu></StyleMenu>

            <WaypointModal
                waypoint={waypointModal}
                onWaypointSave={onWaypointSave}
                onCancel={onWaypointModalCanceled}
            >
            </WaypointModal>
        </div>
    );
}